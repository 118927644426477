import axiosInstance, { BASE_URL } from './axios';

export type RatingBlurbSection = {
  title?: string;
  data?: string;
  title_visible?: boolean;
};

interface PutRatingBlurbSectionVariables {
  ratingBlurbSectionId: string;
  sectionType: string;
  data: RatingBlurbSection;
}

interface PutRatingTableDataVariables {
  ratingTableDataId: string;
  data: any;
}

interface SyncContentVariables {
  documentID: string;
  ratingBlurbSectionParagraphId?: string;
  ratingTableDataId?: string;
}

// export const getProjects = () => {
//   return axiosInstance(`${BASE_URL}/catcher_projects`);
// };

export const getSharedContent = async () => {
  return await axiosInstance(`${BASE_URL}/shared_content`);
};

// export const getProjectWithCreditingPeriod = (id: string, creditingPeriodId: string) => async () => {
//   return await axiosInstance.get(`${BASE_URL}/catcher_projects/${id}/cp/${creditingPeriodId}`);
// };

export const createRatingBlurbSection = async ({
  data
}): Promise<any> => {
  const response = await axiosInstance.post(
    `${BASE_URL}/catcher_projects/rating_blurb_section/`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating rating blurb section');
  }

  return response;
};

export const deleteRatingBlurbSection = async (ratingBlurbSectionId: string): Promise<any> => {
  const response = await axiosInstance.delete(
    `${BASE_URL}/catcher_projects/rating_blurb_section/${ratingBlurbSectionId}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating rating blurb section');
  }

  return response;
};

export const putProjectReportCatcherStatus = async ({
  projectId,
  ghgStandard,
  creditingPeriodId,
  data
}): Promise<any> => {
  const response = await axiosInstance.put(
    `${BASE_URL}/catcher_projects/${projectId}/standard/${ghgStandard}/cp/${creditingPeriodId}/project_report_catcher_status`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating rating blurb section');
  }

  return response;
}

export const putRatingBlurbSection = async ({
  ratingBlurbSectionId,
  sectionType,
  data
}: PutRatingBlurbSectionVariables): Promise<any> => {
  const response = await axiosInstance.put(
    `${BASE_URL}/catcher_projects/rating_blurb_section/${sectionType}/${ratingBlurbSectionId}`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating rating blurb section');
  }

  return response;
};

export const postRatingBlurbSectionImage = async ({
  ratingBlurbSectionId,
  file
}): Promise<any> => {
  const response = await axiosInstance.post(
    `${BASE_URL}/catcher_projects/rating_blurb_section/image/${ratingBlurbSectionId}/upload`,
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating rating blurb section');
  }

  return response;
};

export const putRatingTableData = async ({
  ratingTableDataId,
  data
}: PutRatingTableDataVariables): Promise<any> => {
  const response = await axiosInstance.put(
    `${BASE_URL}/catcher_projects/rating_table_data/${ratingTableDataId}`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating rating blurb section');
  }

  return response;
};

export const postRatingTableData = async (data: any): Promise<any> => {
  const response = await axiosInstance.post(
    `${BASE_URL}/catcher_projects/rating_table_data`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating rating blurb section');
  }

  return response;
};

export const deleteRatingTableData = async (ratingTableDataId: string): Promise<any> => {
  const response = await axiosInstance.delete(
    `${BASE_URL}/catcher_projects/rating_table_data/${ratingTableDataId}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating rating blurb section');
  }

  return response;
};


export const syncDocumentContentFromCKEditor = async ({
  documentID,
  ratingBlurbSectionParagraphId,
  ratingTableDataId
}: SyncContentVariables): Promise<any> => {
  const response = await axiosInstance.post(
    `${BASE_URL}/catcher_projects/sync_document_content_from_ckeditor/${documentID}`,
    {
      ratingBlurbSectionParagraphId,
      ratingTableDataId
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating document content');
  }

  return response;
}

export const extractSDGFromGoogleSpreadsheet = async ({
  projectId,
  creditingPeriodId,
  ghgStandard,
  sheetUrl
}): Promise<any> => {
  const response = await axiosInstance.post(
    `${BASE_URL}/sandbox/populate_sdg`,
    {
      project_id: projectId,
      crediting_period_id: creditingPeriodId,
      ghg_standard: ghgStandard,
      sheet_url: sheetUrl
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'api-key': 'a1baa3a8859f2e3419f32e47fea7d99a164f019923bdba7066e932dd7a52bd48'
      }
    }
  );
  if (!response) {
    throw new Error('Error extracting SDG from Google Spreadsheet');
  }

  return response;
};


// --- shared content --- //
export const putSharedContent = async ({
  sharedContentID,
  data
}): Promise<any> => {
  const response = await axiosInstance.put(
    `${BASE_URL}/shared_content/${sharedContentID}`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error updating rating blurb section');
  }

  return response;
};

export const deleteSharedContent = async ({ sharedContentID }): Promise<any> => {
  if (!sharedContentID) {
    throw new Error('sharedContentID is required');
  }

  const response = await axiosInstance.delete(`${BASE_URL}/shared_content/${sharedContentID}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response) {
    throw new Error('Error deleting shared content');
  }

  return response.data;
};


export const postSharedContent = async (data: any): Promise<any> => {
  const response = await axiosInstance.post(
    `${BASE_URL}/shared_content`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response) {
    throw new Error('Error creating shared content');
  }

  return response;
};

export const getProjectLiveVersions = ({
  ghgStandardId,
  projectId,
  creditingPeriodId
}) => () => {
  return axiosInstance.get(`${BASE_URL}/catcher_projects/project_rating_blurbs_live`, {
    params: {
      project_id: projectId,
      ghg_standard_id: ghgStandardId,
      crediting_period_id: creditingPeriodId
    }
  });
}

export const restoreProductionData = ({
  projectRatingsBlurbLiveId
}) => {
  return axiosInstance.post(`${BASE_URL}/catcher_projects/restore_production_data/${projectRatingsBlurbLiveId}`);
}

export const createCatcherTemplate = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/sandbox/create_catcher_template`, data);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error cloning the project: ${errorMessage}`);
  }
};

export const handleDeepDiveToggle = async (creditingPeriodId, key, value) => {
  try {
    const response = await axiosInstance.post(`/project/deep-dive-data-toogle`, {
      crediting_period_id: creditingPeriodId,
      [key]: value,
    });
    if(response.status === 200) {
      let updatedField = key !== undefined && key === 'enable_ghg_deep_dive' ? 'GHG Risk Factor Summary analysis on platform' : 'SDG Deep Dive analysis on platform';
      let status = value ? 'enabled' : 'disabled';
      return `${updatedField} is now ${status}.`
    } else {
      return 'Failed to update';
    }
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Failed to update: ${errorMessage}`);
  }
};

export const getUser = async (): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/user/me`);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error cloning the project: ${errorMessage}`);
  }
};

export const cloneCatcherData = async (data: any): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/catcher_projects/clone`, data);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error cloning Catcher data: ${errorMessage}`);
  }
};

export const addMissingCaptions = async (id: string, creditingPeriodId: string): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/catcher_projects/create_missing_caption/${id}/cp/${creditingPeriodId}`);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error cloning Catcher data: ${errorMessage}`);
  }
};