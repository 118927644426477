import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Spin, Tooltip } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { updateGHGStandard, getGHGStandardById } from '../../../api/ghg';
import './edit.css';

const EditGHGStandardComponent: React.FC = () => {
  const { id } = useParams<{ id }>();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchGHGStandard() {
      setLoading(true);
      try {
        const ghgStandardData = await getGHGStandardById(id);
        setLoading(false);
        form.setFieldsValue(ghgStandardData.response);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching GHG standard:', error);
      }
    }

    fetchGHGStandard();
  }, [id, form]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await updateGHGStandard(id, values);
      message.success({
        content: 'GHG standard updated successfully',
        duration: 4,
        style: { textAlign: 'right' },
      });
      navigate('/ghg_standards');
    } catch (error: any) {
      console.error('Error updating GHG standard:', error);
      message.error({
        content: error.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className="update-ghg-standard-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className="form-container">
        <h1>Update GHG Standard</h1>
        <Form
          form={form}
          name="updateGHGStandardForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: 'Please input the code of the GHG standard!' }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Full Form&nbsp;
                <Tooltip title="Please only fill this out if you would like the full program name to show on the platform (instead of the acronym).">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />
                </Tooltip>
              </span>
            }
            name="full_form"
            rules={[{ required: true, message: 'Please input the full form of the GHG standard!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: false }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EditGHGStandardComponent;
