import React, { useState, useEffect } from 'react';
import { map, groupBy, find } from 'lodash';
import { Form, Input, Spin, Tooltip, AutoComplete, DatePicker, Select, Radio, Space } from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { getProjectNames } from '../../../api/project';
import { getGHGMethodologies, getGHGStandards } from '../../../api/ghg';

import dayjs from 'dayjs';
import { Option } from 'antd/es/mentions';
import { getProjectTypes } from '../../../api/project_type';
import { getLocations } from '../../../api/location';

const { RangePicker } = DatePicker;

interface ProjectDetailFormProps {
  form: any;
  updateParentData: any;
  dateType: any;
  setDateType: React.Dispatch<React.SetStateAction<any>>;
}

const ProjectDetailForm: React.FC<ProjectDetailFormProps> = ({ form, updateParentData, dateType, setDateType }) => {
  const { id, cpid } = useParams<{ id, cpid: string }>();
  const [ghgStandards, setGHGStandards] = useState([]);
  const [ghgMethodologies, setGHGMethodologies] = useState([]);
  const [projectTypes, setProjectTypes] = useState<{ group: string, types: string[] }[]>([]);
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [projectNames, setProjectNames] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [locationType, setLocationType] = useState<string>('country');
  const [locations, setLocations] = useState<any[]>([]);


  useEffect(() => {
    async function fetchProject() {
      setLoading(true);
      try {
        const [
          projects,
          ghgStandards,
          ghgMethodologies,
          projectTypes,
          locationData
        ] = await Promise.all([
          getProjectNames(),
          getGHGStandards(),
          getGHGMethodologies(),
          getProjectTypes(),
          getLocations()
        ]);
        const projectEnrichedNames = projects.projects.filter(project => project.is_live).map(project => {
          return {
            id: project.id,
            name: `${project.name} - ${project.ghg_standard.code}${project.project_id}`,
          };
        });
        setLocationType('country');
        setProjectNames(projectEnrichedNames);
        setProjectTypes(
          map(groupBy(projectTypes.response, 'project_group.display_name'), (types, groupName) => ({
            group: groupName,
            types: types.map(type => type.display_name)
          }))
        );
        setSearchOptions(projects.projects.filter(project => project.is_live).map(project => ({ value: `${project.name} - ${project.ghg_standard.code}${project.project_id}`, key: project.id })));
        setGHGStandards(ghgStandards.response.map(ghgStandard => ghgStandard.code));
        setGHGMethodologies(ghgMethodologies.response.map(ghgMethodology => ghgMethodology.display_name));
        setLocations(locationData.response);
        updateParentData({
          projectNames: projectEnrichedNames,
          projectTypes: projectTypes.response,
          ghgStandards: ghgStandards.response,
          ghgMethodologies: ghgMethodologies.response,
        });
        form.setFieldsValue({
          locationType: 'country',
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching project:', error);
      }
    }

    fetchProject();
  }, [id, form]);

  const handleSearch = (value: string) => {
    const filteredOptions = projectNames.filter(project =>
      project.name.toLowerCase().includes(value.toLowerCase())
    ).map(projectdata => ({ value: projectdata.name, key: projectdata.id }));
    setSearchOptions(filteredOptions);
  };

  const handleLocationTypeChange = (e) => {
    setLocationType(e.target.value);
  };

  const getLocationCountries = () => {
    return locations
      .filter((location) => location.type === 'country')
      .map((country) => (
        <Option key={country.id} value={country.id}>
          {country.display_name}
        </Option>
      ));
  }

  const getLocationStates = (): any => {
    return map(groupBy(locations.filter((location) => location.type === 'state'), 'parent.display_name'), (types, groupName) => ({
      group: groupName || 'no-parent',
      types: types.map(type => ({ id: type.id, display_name: type.display_name })),
    }));
  }
  const normalizeString = (str) => {
    return str?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.toLowerCase();
  };

  return (
    <>
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className='flex gap-2'>
        <Form.Item
          label="Registry"
          name="ghgStandard"
          rules={[{ required: true, message: 'Please select GHG Standard!' }]}
        >
          <Select>
            {ghgStandards.map((standard: string) => (
              <Option key={standard} value={standard}>{standard}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Project ID"
          name="projectId"
          rules={[{ required: true, message: 'Please input Project ID!' }]}
        >
          <Input />
        </Form.Item>
      </div>
      <Form.Item
        label={
          <span>
            Project Name&nbsp;
            <Tooltip title={(
              <ul className='mt-2'>
                <li>Should be 4 or 5 words, around 50 characters long </li>
                <li>Try to stay as true to the original project name as possible (e.g., delete extraneous words, but don’t change existing ones) </li>
                <li>If changing original project name, be sure and include full project name in blurb Capitalize all words except conjunctions (and, the, in, etc.)</li>
              </ul>
            )}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        name="projectName"
        rules={[
          { required: true, message: 'Please input Project Name!' },
        ]}
      >
        <Input count={{
          show: true
        }}/>
      </Form.Item>
      <div className='flex w-full gap-2'>
        <Form.Item
          label="Methodology"
          name="ghgMethodology"
          className='w-96'
          rules={[{ required: true, message: 'Please select GHG Methodology!' }]}
        >
          <Select
            showSearch
            filterOption={(inputValue, option) =>
              option?.children?.toString().toLowerCase().includes(inputValue.toLowerCase()) ? true : false
            }>
            {ghgMethodologies.map((methodology: string) => (
              <Option
                key={methodology}
                value={methodology}
                style={{ fontWeight: form.getFieldValue('ghgMethodology') === methodology ? 'bold' : 'normal' }}
              >{methodology}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Project Type"
          name="projectType"
          className='w-72'
          rules={[{ required: true, message: 'Please select project type!' }]}
        >
          <Select
            showSearch
            filterOption={(inputValue, option) =>
              option?.children?.toString().toLowerCase().includes(inputValue.toLowerCase()) ? true : false
            }>
            {projectTypes.map((group) => (
              <Select.OptGroup key={group.group} label={<span style={{ fontWeight: 'bold' }}>{group.group}</span>}>
                {group.types.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className='flex gap-4'>
        <Form.Item label="Location" name="locationType">
          <Radio.Group value={locationType} onChange={handleLocationTypeChange}>
            <Radio.Button value="country">Country</Radio.Button>
            <Radio.Button value="state">State</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {locationType === 'country' && (
          <Form.Item label=" " name="projectCountry" className='flex-1'>
            <Select placeholder="Select Country" showSearch 
              filterOption={(inputValue, option) => {
                const normalizedInput = normalizeString(inputValue);
                const normalizedOption = normalizeString(option?.children);
                return normalizedOption?.includes(normalizedInput);
              }}>
              {getLocationCountries()}
            </Select>
          </Form.Item>
        )}
        {locationType === 'state' && (
          <Form.Item label=" " name="projectState" className='flex-1'>
            <Select mode="multiple" placeholder="Select States" showSearch
              filterOption={(inputValue, option) =>{
                const normalizedInput = normalizeString(inputValue);
                const normalizedOption = normalizeString(option?.children);
                return normalizedOption?.includes(normalizedInput);
              }}>
              {getLocationStates().map((group) => (
                <Select.OptGroup key={group.group} label={<span style={{ fontWeight: 'bold' }}>{group.group}</span>}>
                  {group.types.map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.display_name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        )}
      </div>
      <Form.Item
        label="Project Parent"
        name="projectParent"
        rules={[{ required: false }]}
      >
        <AutoComplete
          options={searchOptions}
          placeholder="Type project name or GHG standard + project ID"
          onSearch={handleSearch}
        />
      </Form.Item>
      <Form.Item
        label="Project Duration"
        name="yearRange"
        rules={[{ required: true, message: 'Please select Project Duration!' }]}
      >
        <RangePicker />
      </Form.Item>
    </>
  );
};

export default ProjectDetailForm;
