import { AxiosResponse } from 'axios';
import axiosInstance, { BASE_URL } from './axios';

export const getGHGMethodologies = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/ghg_methodologies');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching GHG methodologies: ${error}`);
  }
};

export const getGHGMethodologyById = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/ghg_methodologies/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching GHG methodology by id: ${error}`);
  }
};

export const createGHGMethodology = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/ghg_methodologies`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the GHG methodology: ${errorMessage}`);
  }
};

export const updateGHGMethodology = async (id: number, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/ghg_methodologies/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the GHG methodology: ${errorMessage}`);
  }
};

export const getGHGStandards = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/ghg_standards');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching GHG standards: ${error}`);
  }
};

export const getGHGStandardById = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/ghg_standards/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error fetching GHG standard by id: ${errorMessage}`);
  }
};

export const createGHGStandard = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/ghg_standards`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the GHG standard: ${errorMessage}`);
  }
};

export const updateGHGStandard = async (id: number, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/ghg_standards/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the GHG standard: ${errorMessage}`);
  }
};

export const deleteGHGMethodology = async (id: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`/ghg_methodologies/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error deleting the GHG methodology: ${errorMessage}`);
  }
};
