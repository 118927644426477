import React, { useState, useEffect, useRef } from 'react';
import Fuse from 'fuse.js';
import { Table, Spin, Input, Button, message, Modal, Form, Tag } from 'antd';
import { LoadingOutlined, SearchOutlined, PlusOutlined, ReloadOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { find } from 'lodash';

import './view.css';
import { createProject, getProjects, updateRatings, downloadProjectCSV } from '../../../api/project';
import { ratingSheetsUrl } from '../../../config/rating.config';
import Search from 'antd/es/input/Search';
import useAuth from '../../../hooks/useAuth';
import { getGHGratingColor } from '../../../utils';
import CreateProjectComponent from '../create/main';
import ProjectDetailForm from '../create/projectdetail';
import dayjs from 'dayjs';

interface Project {
  id: string;
  about: string;
  archived: boolean;
  archived_at: string | null;
  carbon_credit_notes: string;
  created_at: string;
  end_year: number;
  ghg_standard_id: string;
  multiple_registries_notes: string;
  name: string;
  onwatch: number;
  onwatch_reason: string;
  parent_id: string;
  project_id: string;
  revision_date: string | null;
  start_year: number;
  updated_at: string;
  visible_on_web: boolean;
  project_crediting_periods: ProjectCreditingPeriod[];
  ratings: Rating[];
  project_types: ProjectType[];
  proponents: Proponent[];
  ghg_methodologies: GhgMethodology[];
  ghg_standard: GhgStandard;
  locations: Location[];
}

interface ProjectCreditingPeriod {
  id: string;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  crediting_period_id: number;
  end_date: string | null;
  ghg_standard_id: string;
  project_id: string;
  start_date: string | null;
  title: string;
  updated_at: string;
  ratings: Rating;
  project_workflow: ProjectWorkflow;
  project_attributes: ProjectAttribute[];
}

interface ProjectWorkflow {
  id: string;
  crediting_period_id: number;
  state: string;
  staging_id: string;
}

interface Rating {
  id: string;
  additionality: number;
  archived: boolean;
  archived_at: string | null;
  baseline: number;
  created_at: string;
  crediting_period_id: string;
  ghg_risk_rating: number;
  ghg_risk_rating_date: string;
  ghg_risk_rating_narrate: string;
  ghg_risk_rating_notes: string;
  ghg_risk_rating_parent: number;
  leakage: number;
  overcrediting: string;
  overcrediting_score: number;
  overlapping_claims: number;
  permanence: number;
  project_emissions: number;
  sdg_1: number;
  sdg_2: number;
  sdg_3: number;
  sdg_4: number;
  sdg_5: number;
  sdg_6: number;
  sdg_7: number;
  sdg_8: number;
  sdg_9: number;
  sdg_10: number;
  sdg_11: number;
  sdg_12: number;
  sdg_13: number;
  sdg_14: number;
  sdg_15: number;
  sdg_16: number;
  sdg_17: number;
  sdg_1_score: number;
  sdg_2_score: number;
  sdg_3_score: number;
  sdg_4_score: number;
  sdg_5_score: number;
  sdg_6_score: number;
  sdg_7_score: number;
  sdg_8_score: number;
  sdg_9_score: number;
  sdg_10_score: number;
  sdg_11_score: number;
  sdg_12_score: number;
  sdg_13_score: number;
  sdg_14_score: number;
  sdg_15_score: number;
  sdg_16_score: number;
  sdg_17_score: number;
  sdg_impact_rating: number;
  sdg_impact_rating_narrate: string;
  sdg_impact_rating_notes: string;
  sdg_certificate_date: string;
  sdg_certificate_id: string;
  updated_at: string;
}

interface ProjectAttribute {
  id: string;
  attribute_id: boolean;
  attribute_value: boolean;
  attribute_extra_content?: boolean;
  created_at: string;
  updated_at: string;
  attribute: Attribute;
}

interface Attribute {
  id: string;
  name: string;
  display_name: string;
  short_description?: string;
  long_description: string;
  created_at: string;
  updated_at: string;
}

interface ProjectType {
  id: string;
  project_id: string;
  project_type_id: string;
  is_active: boolean;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
  project_type: ProjectTypeDetails;
}

interface ProjectTypeDetails {
  id: string;
  name: string;
  display_name: string;
  created_at: string;
  updated_at: string;
  archived: boolean;
  archived_at: string | null;
  project_group_id: string;
  project_group: ProjectGroup;
}

interface ProjectGroup {
  id: string;
  name: string;
  display_name: string;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
}

interface Proponent {
  id: string;
  project_id: string;
  proponent_id: string;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
  proponent: ProponentDetails;
}

interface ProponentDetails {
  id: string;
  name: string;
  display_name: string;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
}

interface GhgMethodology {
  id: string;
  project_id: string;
  ghg_methodology_id: string;
  is_active: boolean;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
  ghg_methodology: GhgMethodologyDetails;
}

interface GhgMethodologyDetails {
  id: string;
  name: string;
  display_name: string;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
}

interface GhgStandard {
  id: string;
  code: string;
  full_form: string | null;
  description: string | null;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
}

interface Location {
  id: string;
  location_id: string;
  project_id: string;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
  location: LocationDetails;
}

interface LocationDetails {
  id: string;
  type: string;
  name: string;
  display_name: string;
  alias: string | null;
  parent_id: string | null;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
  parent: LocationParent;
}

interface LocationParent {
  id: string;
  type: string;
  name: string;
  display_name: string;
  alias: string | null;
  parent_id: string | null;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
}

const projectColumns = [
  {
    title: 'Project',
    dataIndex: 'name',
    width: '30%',
    key: 'name',
    render: (text, record) => {
      return (
        <div className='text-sm text-ellipsis font-semibold text-blue-500'>{record.ghg_standard.code + record.project_id}: {record.name}</div>
      );
    },
  },
  {
    title: 'Type',
    dataIndex: 'project_types',
    key: 'type',
    render: (text, record) => {
      const active_pt = record.project_types.find(project_type => {
        return project_type.is_active;
      });
      return (
        <div className='flex flex-col'>
          <div className='text-sm text-gray-600 font-semibold'>{active_pt?.project_type.display_name}</div>
          <div className='text-xs mt-1 text-gray-600 '>{active_pt?.project_type.project_group.display_name}</div>
        </div>
      );
    },
  },
  {
    title: 'Location',
    dataIndex: ['location', 0, 'id'],
    key: 'location',
    render: (text, record) => {
      let country = '';
      let region = '';
      const location = record.locations[0]?.location;
      if (location) {
        if (location.type === 'state') {
          country = location.parent?.display_name || '';
          region = location.parent?.parent?.display_name || '';
        } else if (location.type === 'country') {
          country = location.display_name || '';
          region = location.parent?.display_name || '';
        } else if (location.type === 'region') {
          region = location.display_name || '';
          country = '';
        }
      }
      return (
        <div className='flex flex-col'>
          {region && (
            <div className='text-sm text-gray-600 font-semibold'>{region}</div>
          )}
          {country && (
            <div className='text-xs mt-1 text-gray-600 '>{country}</div>
          )}
        </div>
      );
    }
  },
  {
    title: 'Duration/Vintage',
    dataIndex: 'duration',
    key: 'duration',
    render: (text, record) => {
      const creditingPeriod = record.project_crediting_periods[0];
      const startYear = !!creditingPeriod?.start_date && !!creditingPeriod?.end_date ? new Date(creditingPeriod?.start_date).getFullYear() : new Date(record.start_year).getFullYear();
      const endYear = !!creditingPeriod?.start_date && !!creditingPeriod?.end_date ? new Date(creditingPeriod?.end_date).getFullYear() : new Date(record.end_year).getFullYear();
      const title = creditingPeriod?.title;

      return (
        <div className='flex flex-col'>
          <div className='text-sm text-gray-600 font-semibold'>{startYear} - {endYear}</div>
          {title && (
            <div className='text-xs mt-1 text-gray-600'>{title}</div>
          )}
        </div>
      );
    }

  },
  {
    title: 'GHG Rating',
    dataIndex: 'ghg_risk_rating',
    key: 'ghg_risk_rating',
    sorter: (a, b) => {
      let aRating = a.project_crediting_periods[0]?.ratings?.ghg_risk_rating;
      let bRating = b.project_crediting_periods[0]?.ratings?.ghg_risk_rating;

      aRating = (aRating === undefined || aRating < 1) ? 11 : aRating;
      bRating = (bRating === undefined || bRating < 1) ? 11 : bRating;


      // Special handling for ratings of 11
      if (aRating === 11 && bRating !== 11) {
        return 1;  // 'a' should come after 'b'
      } else if (bRating === 11 && aRating !== 11) {
        return -1; // 'b' should come after 'a'
      } else if (aRating === 11 && bRating === 11) {
        return 0;  // Both 'a' and 'b' are 11, no change in order
      }

      return bRating - aRating;
    },
    render: (text, record) => {
      let ghgRating;
      if (record.project_crediting_periods[0]?.ratings?.ghg_risk_rating !== undefined) {
        switch (record.project_crediting_periods[0]?.ratings?.ghg_risk_rating) {
          case 1:
            ghgRating = 'AAA';
            break;
          case 2:
            ghgRating = 'AA';
            break;
          case 3:
            ghgRating = 'A';
            break;
          case 4:
            ghgRating = 'BBB';
            break;
          case 5:
            ghgRating = 'BB';
            break;
          case 6:
            ghgRating = 'B';
            break;
          case 7:
            ghgRating = 'C';
            break;
          case 8:
            ghgRating = 'D';
            break;
          case 0:
            ghgRating = 'N/A';
            break;
          default:
            ghgRating = '--';
            break;
        }
      }
      

      return (
        <div className='text-base text-gray-600 font-semibold' style={{
          color: getGHGratingColor(ghgRating) || 'black'
        }}>{ghgRating}</div>
      );
    }
  },
  {
    title: 'SDG Rating',
    dataIndex: 'sdg_impact_rating',
    key: 'sdg_impact_rating',
    sorter: (a, b) => {
      return a.project_crediting_periods[0]?.ratings?.sdg_impact_rating - b.project_crediting_periods[0]?.ratings?.sdg_impact_rating;
    },
    render: (text, record) => {
      let sdgImpactRating;
      if (record.project_crediting_periods[0]?.ratings?.sdg_impact_rating !== undefined) {
        switch (record.project_crediting_periods[0]?.ratings?.sdg_impact_rating) {
          case 1:
            sdgImpactRating = '+1';
            break;
          case 2:
            sdgImpactRating = '+2';
            break;
          case 3:
            sdgImpactRating = '+3';
            break;
          case 4:
            sdgImpactRating = '+4';
            break;
          case 5:
            sdgImpactRating = '+5';
            break;
          case 0:
            sdgImpactRating = '-';
            break;
          default:
            sdgImpactRating = '-';
            break;
        }
      }

      return (
        <div className='flex flex-col'>
          <div className='text-base text-gray-600 font-semibold'>{sdgImpactRating}</div>
        </div>
      );
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => {
      return ('' + a.project_crediting_periods[0]?.project_workflow?.state).localeCompare(b.project_crediting_periods[0]?.project_workflow?.state);
    },
    render: (text, record) => {
      const creditingPeriod = record.project_crediting_periods[0];
      let status;
      let color = 'text-gray-600';
      switch (creditingPeriod?.project_workflow?.state) {
        case 'draft':
          status = 'Draft';
          color = 'blue';
          break;
        case 'staging':
          status = 'Staging';
          color = 'orange';
          break;
        case 'production':
          status = 'Production';
          color = 'green';
          break;
        default:
          status = '--';
          break;
      }

      return (
        <Tag color={color}>{status}</Tag>
      );
    }
  }
];

const ViewProjectComponent: React.FC = () => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [mainDataSource, setMainDataSource] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [openNewProjectModal, setOpenNewProjectModal] = useState(false);
  const [form] = Form.useForm();
  const [url, setUrl] = useState('');
  const navigate = useNavigate();
  const fuseRef = useRef<any>();
  const { logout } = useAuth();
  const [parentData, setParentData] = useState<any>({});
  const [dateType, setDateType] = useState<any>('date');


  const fetchData = async () => {
    try {
      setLoading(true);
      const projectsData = await getProjects();
      const processedProjects: Project[] = [];

      projectsData.projects = projectsData.projects.filter(project => project.is_live === false);
      projectsData.projects.forEach(project => {
        if (project.project_crediting_periods.length === 1 && project.project_crediting_periods[0].crediting_period_id === 0) {
          processedProjects.push(project);
        } else {
          project.project_crediting_periods.forEach(creditingPeriod => {
            const newProject = { ...project, project_crediting_periods: [creditingPeriod] };
            processedProjects.push(newProject);
          });
        }
      });

      setProjects(processedProjects);
      setLoading(false);
      setMainDataSource(processedProjects);
      setDataSource(processedProjects);
      fuseRef.current = new Fuse(processedProjects, {
        threshold: 0.1,
        keys: [
          'name',
          'project_id',
          'project_types.project_type.display_name',
          'project_types.project_type.project_group.display_name',
          'ghg_standard.code',
          'locations.location.display_name',
          'locations.location.parent.display_name',

          {
            name: 'ghg_standard+project_id',
            weight: 0.5,
            getFn: (project) => `${project.ghg_standard.code}${project.project_id}`
          },
          {
            name: 'workflow_state',
            weight: 0.5,
            getFn: (project) => project.project_crediting_periods?.[0].project_workflow?.state
          },
        ]
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSearch = (value: string) => {
    if (value === '') {
      setDataSource(mainDataSource);
    } else {
      const searchResults = fuseRef.current.search(value);
      setDataSource(searchResults.map(result => result.item));
    }
  }

  const handleSearch = (value: string) => {
    setSearchText(value);
    onSearch(value);
  };

  const filteredProjects = projects.filter(project =>
    project.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleLogout = () => {
    logout();
  };

  const refreshSDGRatings = async () => {
    try {
      setOpen(false);
      setLoading(true);
      const url = form.getFieldValue('url');
      const response = await updateRatings(url);
      await fetchData();
      setLoading(false);
      message.success({
        content: 'Refresh ratings successfully',
        duration: 4,
        style: { textAlign: 'right' },
      });
      return response;
    } catch (err: any) {
      console.error('Error refreshing ratings:', err);
      message.error({
        content: err.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    } finally {
      setLoading(false);
    }
  }

  const downloadCSV = async () => {
    try {
      setLoading(true);
      await downloadProjectCSV();
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.error('Error downloading csv:', err);
      message.error({
        content: err.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    }
  }

  const onSDGRefresh = () => {
    Modal.confirm({
      title: 'Confirmation',
      content: (
        <div>
          <div className='text-sm text-gray-600 mt-2'>
            <p className='font-semibold'>This will refresh the SDG ratings for all the projects from <a href={ratingSheetsUrl} target="_blank" rel="noreferrer" className='text-blue-500 underline'>SDG Scoring Sheet - [Parser Testing] SDG Scores to Sandbox</a></p>
            <p>Please reach out to tech team, if there is a change in the url or name of the sheet</p>
          </div>
        </div>
      ),
      async onOk() {
        try {
          setLoading(true);
          const response = await updateRatings(ratingSheetsUrl);
          await fetchData();
          setLoading(false);
          message.success({
            content: 'SDG ratings are refreshed successfully.',
            duration: 2,
            style: { textAlign: 'right' },
          });
        } catch (err: any) {
          setLoading(false);
          message.error({
            content: err.message || 'There is an error refreshing SDG ratings. Please reach out to the tech team.',
            duration: 4,
            style: { textAlign: 'right' },
          });
        } finally {
          Modal.destroyAll();
        }
      },
    });
  };

  const handleOk = async () => {
    try {
      await form.validateFields();
      refreshSDGRatings();
    } catch (errorInfo) {
      console.error('URL is required');
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const updateParentData = (data: any) => {
    setParentData(data);
  }

  const nextStep = async () => {
    try {
      await form.validateFields();
      try {
        setLoading(true);
        const projectResponse = await createProjectData();
        setLoading(false);
        message.success({
          content: 'Project Created Successfully.',
          duration: 2,
          style: { textAlign: 'right' },
        });
        navigate(`/project/edit/${projectResponse.id}/cp/${projectResponse.project_crediting_periods[0].crediting_period_id}/step/0`, {
          replace: true,
        });
      } catch (err: any) {
        console.error('Error creating project', err);
        setLoading(false);
        message.error({
          content: err.message || 'Unable to create the project',
          duration: 4,
          style: { textAlign: 'right' },
        });
      } finally {
      }
    } catch (error) {
      message.error({
        content: 'Please fill in all required fields before proceeding.',
        duration: 4,
        style: { textAlign: 'right' },
      });
    }
  };

  const createProjectData = async () => {
    const formData = form.getFieldsValue();
    const formatDate = date => dayjs(date).format('YYYY-MM-DD');
    const data = {
      projectName: formData.projectName,
      about: formData.about,
      projectId: formData.projectId,
      ...(formData.projectParent && {
        projectParentId: find(parentData.projectNames, { name: formData.projectParent })?.id,
      }),
      projectTypeId: find(parentData.projectTypes, projectType => formData.projectType === projectType.display_name)?.id,
      ghgStandardId: find(parentData.ghgStandards, ghgStandard => formData.ghgStandard === ghgStandard.code)?.id,
      ghgMethodologyId: find(parentData.ghgMethodologies, ghgMethodology => formData.ghgMethodology === ghgMethodology.display_name)?.id,
      ...(formData.yearRange?.[0] && {
        projectStartDate: formatDate(formData.yearRange[0]),
      }),
      ...(formData.yearRange?.[1] && {
        projectEndDate: formatDate(formData.yearRange[1]),
      }),
      ...(formData.locationType === 'state' && formData.projectState && {
        locationIds: formData.projectState,
      }),
      ...(formData.locationType === 'country' && formData.projectCountry && {
        locationIds: formData.projectCountry ? [formData.projectCountry] : [],
      }),
    };
    const projectResponse = await createProject(data);
    return projectResponse.response;
  };

  const onFinish = async (values: any) => {

  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Form submission failed:', errorInfo);
  };

  return (
    <div className="table-container">
      <Spin spinning={loading} indicator={<LoadingOutlined className='text-2xl text-white' spin />} fullscreen />
      <div className='flex justify-between items-center mb-4'>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search project"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          className='flex-1 mr-2 max-w-prose'
        />
        <div>
          <Button ghost type="primary" onClick={onSDGRefresh} icon={<ReloadOutlined />}>
            SDG Ratings
          </Button>
          <Button className='ml-1' type="primary" icon={<CloudDownloadOutlined />} onClick={downloadCSV}>Download CSV</Button>
          <Modal
            title="Enter URL"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={form}>
              <Form.Item
                name="url"
                rules={[{ required: true, message: 'Please enter URL' }]}
              >
                <Input.TextArea placeholder="Enter URL" />
              </Form.Item>
            </Form>
          </Modal>
          <Button className='ml-1' type="primary" icon={<PlusOutlined />} onClick={() => setOpenNewProjectModal(true)}>New Project</Button>
        </div>
      </div>
      <Modal
        title={(
          <div className='flex border-solid border-b border-0 pb-2 border-gray-500'>
            New Project
          </div>
        )}
        open={openNewProjectModal}
        centered
        width={'600px'}
        closable={false}
        mask
        maskClosable={false}
        keyboard={false}
        onOk={nextStep}
        okText='Create Project'
        onCancel={() => setOpenNewProjectModal(false)}
      >
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
        <Form
          form={form}
          name="updateProjectForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <ProjectDetailForm form={form} updateParentData={updateParentData} dateType={dateType} setDateType={setDateType} />
        </Form>
      </Modal>
      <Table
        columns={projectColumns}
        // dataSource={filteredProjects.map(project => ({ ...project, key: project.id }))}
        dataSource={dataSource}
        rowKey={(record) => `${record.id}${record.project_crediting_periods[0] ? record.project_crediting_periods[0].crediting_period_id : ''}`}
        onRow={(record: any) => ({
          onClick: () => navigate(`/project/edit/${record.id}/cp/${record.project_crediting_periods[0]?.crediting_period_id}/step/0`),
          style: { cursor: 'pointer' }
        })}
      />
    </div>
  );
};

export default ViewProjectComponent;
